var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container",attrs:{"id":"app"}},[_c('header',{staticClass:"header-module row no-gutters justify-content-between mb-0"},[_c('div',{staticClass:"col ehoi-logo"},[_c('a',{staticClass:"logo",attrs:{"href":_vm.mandantHref[_vm.$store.getters.getAppMandant] ?? "https://www.e-hoi.de"}},[_c('img',{staticClass:"img",attrs:{"src":require('@/assets/images/'+(_vm.mandantLogo[_vm.$store.getters.getAppMandant] ?? 'website-logo_101.png')),"alt":""}})])]),_c('div',{staticClass:"col-auto tuv-logo text-left"},[(_vm.$store.getters.getAppMandant == 101)?_c('span',{staticClass:"hotline no-underline pro default"},[_c('span',{staticClass:"d-none d-lg-inline"},[_c('span',[_vm._v(_vm._s(_vm.$t('personal_consulting')))]),_vm._m(0),_c('a',{staticClass:"pro default",attrs:{"href":"mailto:partnerprogramm@e-hoi.de"}},[_vm._v("partnerprogramm@e-hoi.de")])]),_vm._m(1)]):(_vm.$store.getters.getAppMandant == 106)?_c('div',{staticClass:"hotline no-underline pro default"},[_c('span',{staticClass:"d-none d-lg-inline"},[_c('span',[_vm._v(_vm._s(_vm.$t('personal_consulting')))]),_vm._m(2),_c('a',{staticClass:"pro default",attrs:{"href":"mailto:sales@zeetours.nl"}},[_vm._v("sales@zeetours.nl")])]),_vm._m(3)]):(_vm.$store.getters.getAppMandant == 109)?_c('div',{staticClass:"hotline no-underline pro default"},[_c('span',{staticClass:"d-none d-lg-inline"},[_c('span',[_vm._v(_vm._s(_vm.$t('personal_consulting')))]),_vm._m(4),_c('a',{staticClass:"pro default",attrs:{"href":"mailto:comercial@unmundodecruceros.com"}},[_vm._v("comercial@unmundodecruceros.com")])]),_vm._m(5)]):_vm._e()]),_c('div',{staticClass:"col-auto d-flex"},[_c('Flags')],1),_vm._m(6)]),_c('router-view'),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('em',[_c('i',{staticClass:"fa fa-phone"}),_c('span',{staticClass:"phonenumber"},[_vm._v("+49 69 20456 - 788")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"d-inline d-lg-none mr-3"},[_c('i',{staticClass:"fa fa-phone"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('em',[_c('i',{staticClass:"fa fa-phone"}),_c('span',{staticClass:"phonenumber"},[_vm._v("010 28 23 800")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"d-inline d-lg-none mr-3"},[_c('i',{staticClass:"fa fa-phone"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('em',[_c('i',{staticClass:"fa fa-phone"}),_c('span',{staticClass:"phonenumber"},[_vm._v("91 542 76 63")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"d-inline d-lg-none mr-3"},[_c('i',{staticClass:"fa fa-phone"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-auto navbar-light"},[_c('button',{staticClass:"navbar-toggler collapsed d-block d-lg-none",attrs:{"type":"button","data-toggle":"collapse","aria-expanded":"false","data-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent"}},[_c('i',{staticClass:"fa fa-bars"})])])
}]

export { render, staticRenderFns }